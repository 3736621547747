const FR = require('../I18n/lang/fr');
const EN = require('../I18n/lang/en');
const ES = require('../I18n/lang/es');

export const translation = (language, key) => {
    if (language === 'en') {
        return EN[key]
    }
    else if (language === 'fr') {
        return FR[key]
    }
    else if (language === 'es') {
        return ES[key]
    }
}