// index.js

import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers } from "redux";

import { Provider } from "react-redux"; // Importez le Provider depuis react-redux
import App from "./App";
import languageReducer from "./store/LanguageReducer";
const store = createStore(
  combineReducers({
    languageReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
