import React from "react";
import { translation } from "../I18n/i18n";
import { useDispatch, useSelector } from "react-redux";

function Navbarlang() {
  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();
  return (
    <div>
      <div className="box-btn">
        <div
          className="btn btn-fr"
          style={{ padding: 0 }}
          onClick={() => dispatch({ type: "fr" })}
        >
          <img src="../../france.svg" alt="fr" width="30" heigh="20"></img>
        </div>
        <div
          className="btn btn-en"
          style={{ padding: 0 }}
          style={{ padding: 0 }}
          onClick={() => dispatch({ type: "en" })}
        >
          <img src="../../uk.svg" alt="fr" width="30" heigh="20"></img>
        </div>
        <div
          className="btn btn-ko"
          style={{ padding: 0 }}
          onClick={() => dispatch({ type: "es" })}
        >
          <img src="../../spain.svg" alt="ko" width="30" heigh="20"></img>
        </div>
      </div>
    </div>
  );
}

export default Navbarlang;
