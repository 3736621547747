import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";
import NavBarComponent from "./NavBarComponent";

function Hallstattdetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();

  return (
    <div>
      <NavBarComponent />
      <div className="hallstatt">
        <div className="Hallstatt-back">
          <button
            style={{
              marginTop: "10px",
              border: "none",
              outline: "none",
              marginLeft: "300px",
              borderRadius: "20px",
              backgroundColor: "#3f63ef",
              padding: "10px 20px",
            }}
          >
            <Link to="/card" style={{ color: "white" }}>
              {" "}
              Retour{" "}
            </Link>
          </button>
        </div>

        <div className="hallstatt-video">
          <video controls width="100%" style={{ marginLeft: -150 }}>
            {" "}
            <source
              src="../../Teaser-Festival-hallstatt.mp4"
              type="video/mp4"
            ></source>
          </video>

          {/*  */}
          <div className="title-hallstatt">
            <h1>{translation(lang, "festival")}</h1>
          </div>
        </div>
      </div>
      <div className="client-center">
        <div className="client">
          <div className="expertise-center">
            <span className="bold-expertise">
              {translation(lang, "expertise")}
            </span>{" "}
            <span>{translation(lang, "brand-hallstatt")}</span>
          </div>
          <div className="secteur">
            <span className="bold-expertise">
              {translation(lang, "secteur")}
            </span>{" "}
            <span>{translation(lang, "sect-hallstatt")}</span>
          </div>
        </div>
      </div>
      <div className="concept-hist">
        <div className="concept">
          <h2>{translation(lang, "concept")}</h2>
          <p>{translation(lang, "concept-hallstatt-presentation")}</p>
          <img
            src="../../animation-logo.gif"
            width="500px"
            type="video/gif"
          ></img>
        </div>
        <div className="text-histoire">
          <h2>{translation(lang, "histoire")}</h2>
          <p>
            {translation(lang, "presentation-all-hallstatt")}
            <span style={{ color: "#61C970", fontWeight: 600 }}>
              {" "}
              {translation(lang, "cerf")} (karv)
            </span>{" "}
            {translation(lang, "personality-cerf")}
            <span style={{ color: "#61C970", fontWeight: 600 }}>
              {" "}
              {translation(lang, "ours")} (arz)
            </span>{" "}
            {translation(lang, "personality-ours")}
            <span style={{ color: "#61C970", fontWeight: 600 }}>
              {" "}
              {translation(lang, "corbeaux")} (bran)
            </span>{" "}
            {translation(lang, "personality-corbeaux")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hallstattdetails;
