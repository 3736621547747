import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faScroll,
  faBook,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

function NavBarComponent() {
  const [isMobile, setIsMobile] = useState(false);

  // Fonction pour d�tecter si l'utilisateur est sur un appareil mobile
  const detectMobile = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Ex�cuter la fonction de d�tection au chargement initial de la page
  useEffect(() => {
    detectMobile();
    // Ajouter un �couteur d'�v�nements pour d�tecter les changements de taille d'�cran
    window.addEventListener("resize", detectMobile);
    // Nettoyer l'�couteur d'�v�nements lorsque le composant est d�mont�
    return () => window.removeEventListener("resize", detectMobile);
  }, []);

  const handlePhoneClick = () => {
    window.location.href = "tel:0643567176"; // Remplacez par votre num�ro de t�l�phone
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto flex-column">
            <Nav.Link href="/" className="rounded-circle">
              <FontAwesomeIcon icon={faHome} /> Accueil
            </Nav.Link>

            <Nav.Link href="/card" to="/card" className="rounded-circle">
              <FontAwesomeIcon icon={faScroll} /> Portfolio
            </Nav.Link>

            {!isMobile && (
              <Nav.Link href="/cv" className="rounded-circle">
                <FontAwesomeIcon icon={faBook} /> CV
              </Nav.Link>
            )}

            <Nav.Link onClick={handlePhoneClick} className="rounded-circle">
              <FontAwesomeIcon icon={faPhoneAlt} /> Tel
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarComponent;
