import React,{useEffect} from 'react'

function Locapic() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
       
    test
        </div>
    )
}

export default Locapic
