import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";
import NavBarComponent from "./NavBarComponent";

function Voyage() {
  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBarComponent />
      <div className="hallstatt">
        <div className="voyage-back">
          {" "}
          <button
            style={{
              marginTop: "10px",
              border: "none",
              outline: "none",
              marginLeft: "300px",
              borderRadius: "20px",
              backgroundColor: "#3f63ef",
              padding: "10px 20px",
            }}
          >
            <Link to="/card" style={{ color: "white" }}>
              {" "}
              Retour{" "}
            </Link>
          </button>
        </div>
        <div className="hallstatt-video">
          <video controls width="100%" style={{ marginLeft: -150 }}>
            {" "}
            <source src="../../voyage-sens.mp4" type="video/mp4"></source>
          </video>
          {/*  */}
          <div className="title-voyage">
            <h1>{translation(lang, "voyage")}</h1>
          </div>
        </div>
      </div>

      <div className="client-center">
        <div className="client1">
          <div className="expertise-center">
            <span className="bold-expertise">
              {translation(lang, "expertise")}
            </span>{" "}
            <span>Branding | Concept | Video</span>
          </div>
          <div className="secteur">
            <span className="bold-expertise">
              {translation(lang, "secteur")}
            </span>{" "}
            <span> Voyage | Culturel | Politique</span>
          </div>
        </div>
      </div>

      <div className="concept-hist">
        <div className="concept">
          <h2>{translation(lang, "concept")}</h2>
          <p>{translation(lang, "desc-travel")}</p>
        </div>
        <div className="text-histoire">
          <img src="../../Chateau-smith.png" alt="chateau"></img>
        </div>
      </div>

      <div className="colorfooter">
        <Link
          class="mentionlegale"
          to="/mentionslegales"
          aria-label="Mentionlegales"
        >
          mentions légales / RGPD | 
        </Link>
        <a href="#">
          <span>donka creation</span>
        </a>
      </div>
    </div>
  );
}

export default Voyage;
