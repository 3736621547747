import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";
import NavBarComponent from "./NavBarComponent";

function Card() {
  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();

  return (
    <div>
      <NavBarComponent />
      <div className="flex-portfolio">
        <Link to="/card/grandfrais">
          <div className="expanding__card">
            <div className="expanding__card__imgBox">
              <img
                src="../../grand-frais.jpg"
                alt="grand frais"
                width="290px"
                height="290px"
              />
            </div>
            <div className="expanding__card__content">
              <h2>{translation(lang, "title-portfolio-grand")}</h2>
              <div className="expanding__card__content_separator"></div>
              <p>{translation(lang, "presentation-grand")}</p>
            </div>
          </div>
        </Link>
        <Link to="/card/voyage">
          <div className="expanding__card">
            <div className="expanding__card__imgBox">
              <img
                src="../../voyage.png"
                alt="voyage-logo"
                width="290px"
                height="290px"
              />
            </div>
            <div className="expanding__card__content">
              <h2>{translation(lang, "title-portfolio-voyage")}</h2>
              <div className="expanding__card__content_separator"></div>
              <p>{translation(lang, "presentation-voyage")}</p>
            </div>
          </div>
        </Link>

        <Link to="/card/hallstatt">
          <div className="expanding__card">
            <div className="expanding__card__imgBox">
              <img
                src="../../logo-hallstatt.png"
                alt="ecolodge"
                width="290px"
                height="290px"
              />
            </div>
            <div className="expanding__card__content">
              <h2>{translation(lang, "title-portfolio-hallstatt")}</h2>
              <div className="expanding__card__content_separator"></div>
              <p>{translation(lang, "presentation-hallstatt")}</p>
            </div>
          </div>
        </Link>

        <Link to="/card/echo">
          <div className="expanding__card">
            <div className="expanding__card__imgBox">
              <img
                src="../../echo.png"
                alt="ecolodge"
                width="290px"
                height="290px"
              />
            </div>
            <div className="expanding__card__content">
              <h2>{translation(lang, "title-portfolio-echo")}</h2>
              <div className="expanding__card__content_separator"></div>
              <p>{translation(lang, "presentation-echo")}</p>
            </div>
          </div>
        </Link>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../baggy-macbook.jpg"
              alt="baggy"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-baggy")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-baggy")} </p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../regards-exterieurs.jpg"
              alt="regards-exterieurs"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-jardin")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p> {translation(lang, "presentation-jardin")} </p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../nemausus-sante.jpg"
              alt="nemausus-sante"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-sante")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-sante")}</p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../application-localisation.jpg"
              alt="application-localisation"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-locapic")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-locapic")}</p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../my-natural-health.jpg"
              alt="my natural health"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-natural")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-sante")}</p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../ecolodge.jpg"
              alt="ecolodge"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-ecolodge")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-ecolodge")}</p>
          </div>
        </div>

        <div className="expanding__card">
          <div className="expanding__card__imgBox">
            <img
              src="../../illustration-cosmonaute.jpg"
              alt="cosmonote-donka-dessin"
              width="290px"
              height="290px"
            />
          </div>
          <div className="expanding__card__content">
            <h2>{translation(lang, "title-portfolio-cosmonaute")}</h2>
            <div className="expanding__card__content_separator"></div>
            <p>{translation(lang, "presentation-cosmonaute")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
