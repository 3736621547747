import React, { useEffect, useState } from "react";
import "./App.css";
import Banner from "./composants/Banner";
import Contact from "./composants/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cv from "./composants/Cv";
import Mentionlegales from "./composants/Mentionlegales";
import ReactGa from "react-ga";
import Page404 from "./composants/Page404";
import { Helmet } from "react-helmet";
import Hallstattdetails from "./composants/Hallstattdetails";
import Voyage from "./composants/Voyage";
import Echo from "./composants/Echo";
import Locapic from "./composants/Locapic";
import Grandfrais from "./composants/Grandfrais";
import Nemausus from "./composants/Nemausus";
import Navbarlang from "./composants/Navbarlang";
import Card from "./composants/Card";
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Effet pour charger la pr�f�rence pr�c�dente de l'utilisateur � partir du local storage
  useEffect(() => {
    const savedMode = localStorage.getItem("mode");
    if (savedMode === "dark") {
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    ReactGa.initialize("UA-149495478-1");

    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className={`App ${isDarkMode ? "dark-mode" : ""}`}>
      <Navbarlang />
      <Helmet>
        <link rel="canonical" href="https:/donka-creation.fr"></link>
        <meta
          name="description"
          content="Donka PRAT graphiste / webdesigner / developpeur web auto entrepreneuse et freelance bas�e sur N�mes, titulaire d'un Master Chef de Projet Digital."
        />
        <meta
          name="keywords"
          content="HTML, CSS, JavaScript, Php, SQL, MySQL graphiste, webdesigner, developpeur web, nimes, freelance"
        ></meta>
        <meta name="author" content="Donka prat" />
        <title>Donka creation, graphiste, webdesigner et developpeur web</title>
      </Helmet>
      <Router>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Banner
                isDarkMode={isDarkMode}
                toggleMode={() => setIsDarkMode(!isDarkMode)}
              />
            }
          />
          <Route path="/card" element={<Card />} />
          {/* <Route path="/book" component={Book} /> */}x
          <Route path="/cv" element={<Cv />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/mentionslegales" element={<Mentionlegales />} />
          <Route path="/card/hallstatt" element={<Hallstattdetails />} />
          <Route path="/card/voyage" element={<Voyage />} />
          <Route path="/card/echo" element={<Echo />} />
          <Route path="/card/locapic" element={<Locapic />} />
          <Route path="/card/grandfrais" element={<Grandfrais />} />
          <Route path="/card/emaususanteasso" element={<Nemausus />} />
          <Route element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
