import React, { useState } from "react";
import {
  faPhone,
  faMapMarkerAlt,
  faCar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timeline } from "antd";
// import "antd/dist/antd.css";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";
import Navbarlang from "./Navbarlang";
import NavBarComponent from "./NavBarComponent";

function Cv() {
  const [mode] = useState("left");
  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();
  return (
    <div className="cv-container">
      <NavBarComponent />
      <Navbarlang />

      <div className="cv-row-one">
        <h2>Donka Prat</h2>
        <h1>Graphiste / web designer / developpeur web JS</h1>
      </div>

      <div className="cv-row-two">
        <div className="left-cv-row">
          <div>
            06.43.56.71.76
            <FontAwesomeIcon icon={faPhone} />
          </div>
          <div>
            5 Rue Saint Thomas
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
        </div>

        <div className="right-cv-row">
          <div>
            <FontAwesomeIcon icon={faUsers} />
            @donkacreation
          </div>
          <div>
            <FontAwesomeIcon icon={faCar} />
            Permis B
          </div>
        </div>
      </div>

      <div className="cv-row-two-pics">
        <img
          src="../../donka.jpg"
          alt="cv-avatar"
          className="middle-cv-row"
        ></img>
      </div>

      <div className="cv-row-three">
        <div className="cv-col-one">
          <div style={{ marginBottom: 65, marginTop: 100 }}>
            <h2>QUI SUIS-JE ?</h2>
            <p>
              Donka PRAT, 25 ans développeur web, web designer et graphiste sur
              Nimes. Titulaire d’un Master en informatique obtenu au sein de
              Keyce Academy, j'ai complété ma formation par une année de
              spécialisation en conception au sein du CESI de Montpellier.
              Développeur informatique depuis plus de 3 ans au sein d'une
              Agence Web nimoise j'interviens en parallèle comme formatrice
              vacataire depuis 2023 en Communication digitale auprès
              d'étudiants en BTS au sein de Campus Avenir.
            </p>
          </div>
          <div style={{ marginBottom: 65, marginTop: 100 }}>
            <h2>COMPÉTENCES</h2>
            <h3>graphisme</h3>
            <div className="graph" style={{ marginBottom: 65 }}>
              <img alt="photoshop" src="../../adobephotoshop.svg"></img>
              <img alt="illustrator" src="../../adobeillustrator.svg"></img>
              <img alt="indesign" src="../../adobeindesign.svg"></img>

              <img alt="lightroom" src="../../adobelightroomclassic.svg"></img>
              <img alt="premiere" src="../../adobepremiere.svg"></img>
              <img alt="xd" src="../../adobexd.svg"></img>
            </div>
            <h3>Web-design</h3>
            <div className="graph" style={{ marginBottom: 65 }}>
              <img alt="html5" src="../../html5.svg"></img>
              <img alt="css3" src="../../css3.svg"></img>
              <img alt="github" src="../../bootstrap.svg"></img>
              <img alt="nodejs" src="../../node-dot-js.svg"></img>
              <img alt="react" src="../../react.svg"></img>
              <img alt="redux" src="../../redux.svg"></img>
              <img alt="postman" src="../../postman.svg"></img>
              <img alt="mongodb" src="../../mongodb.svg"></img>
              <img alt="express" src="../../expressjs.png"></img>
              <img alt="github" src="../../github.svg"></img>
              <img alt="sass" src="../../sass.jpg"></img>
            </div>
            <h3>Réseaux sociaux</h3>
            <div className="graph" style={{ marginBottom: 65 }}>
              <img src="../../facebook-with-circle.svg" alt="facebook"></img>
              <img src="../../instagram-with-circle.svg" alt="instagram"></img>
              <img src="../../linkedin-with-circle.svg" alt="linkedin"></img>
            </div>
          </div>

          <div style={{ marginBottom: 65, marginTop: 100 }}>
            <h2>SOFT SKILLS</h2>
            <p>La gestion du temps</p>
            <p>La communication</p>
            <p>La créativité</p>
            <p>L'esprit d'équipe</p>
            <p>L'esprit d'analyse</p>
          </div>

          <div style={{ marginBottom: 65, marginTop: 100 }}>
            <h2>CENTRES D'INTERETS</h2>
            <p>
              Arts graphiques | Dessin | Expos | Numérique | Travaux mannuels
              | Curieuse des nouveautés
            </p>
          </div>
        </div>

        <div
          className="cv-col-two"
          style={{ marginBottom: 65, marginTop: 100 }}
        >
          <div>
            <h2 className="cv-exp-title">EXPÉRIENCE PROFESSIONNELLE</h2>
            <Timeline mode={mode}>
              <Timeline.Item label="Formatrice">
                <h2>Campus Avenir </h2>
                <h3>Depuis Octobre 2023 à aujourd'hui</h3>

                <p>
                  J'interviens en tant que formatrice pour l'épreuve de
                  Communication Digitale (E5) du BTS Assurance au sein de Campus
                  Avenir.
                  <br />
                  <b>Mon intervention englobe :</b>
                  <br />
                  <b style={{ color: "#3F63EF" }}>
                    {" "}
                    • Communication digitale{" "}
                  </b>
                  <br />
                  <b style={{ color: "#3F63EF" }}>
                    • Utilisation des systèmes d'information et des outils
                    numériques
                  </b>
                </p>
              </Timeline.Item>

              <Timeline.Item label="Developpeur informatique">
                <h2>ID-Alizes (CDD)</h2>
                <h3>Depuis Octobre 2023 à aujourd'hui</h3>
                <p>
                  Développeur informatique
                  <br />
                  <b> Langages utilisés : </b>
                  <b style={{ color: "#3F63EF" }}>
                    React Native, node Js, Php, mySQL/creation API Rest, GSite,
                    SVN
                  </b>
                </p>
              </Timeline.Item>
              <Timeline.Item>
                <h2>ID-Alizes (Contrat d'appprentissage)</h2>
                <h3>Septembre 2020 a Septembre 2023</h3>
                <p>
                  Développeur d'<b>application Mobile/Web</b>
                  <b> Langages utilisés : </b>
                  <b style={{ color: "#3F63EF" }}>
                    React Native, node Js, Php, mySQL/creation API Rest, GSite,
                    SVN
                  </b>
                </p>
              </Timeline.Item>
              <Timeline.Item label="Developpeur Web Fullstack">
                <h2>Nemausus Consulting (CDD Mission)</h2>
                <h3>17 juin 2019 au 13 Janvier 2020</h3>
                <p>
                  Développement d’une <b>plateforme</b> dans le{" "}
                  <b>domaine de la Santé </b>pour le compte d’une Mutuelle
                  cliente du Cabinet. <b>Langages utilisés : </b>
                  <b style={{ color: "#3F63EF" }}>
                    React, node Js, Express, mongo db / creation API Rest,
                    Github
                  </b>
                </p>
              </Timeline.Item>

              <Timeline.Item label="Freelance">
                <h2>Donka Creation</h2>
                <h3>Depuis février 2019</h3>
                <p>
                  Projets <b>graphiques</b> et <b>web</b>
                </p>
              </Timeline.Item>

              <Timeline.Item label="Stage Web master">
                <h2>Carlove</h2>
                <h3>9 juillet - 17 août 2018</h3>
                <p>
                  Mise en place des pages sur{" "}
                  <b style={{ color: "#3F63EF" }}> wordpress </b> sur les sites{" "}
                  <b>carlove, moteur9, Carclim</b> Études et créations d’images
                  / <b>Slogans / vidéos et logos garagistes</b> à créer /
                  redessiner
                </p>
              </Timeline.Item>
              <Timeline.Item label=" Stage Graphiste">
                <h2>Stage Waliceo</h2>
                <h3>1 Aout - 29 Septembre 2017</h3>
                <p>
                  Missions ponctuelles pour la{" "}
                  <b>communauté sourde, recherches </b>
                  et <b>réalisations</b> de <b>nouveaux visuels</b> ...
                </p>
              </Timeline.Item>
            </Timeline>
          </div>

          <div>
            <h2
              className="cv-exp-title"
              style={{ marginBottom: 65, marginTop: 100 }}
            >
              FORMATIONS
            </h2>
            <Timeline mode={mode}>
              <Timeline.Item label="Concepteur développeur d'application">
                <h2>CESI</h2>
                <h3>Octobre 2022 à Septembre 2023</h3>
                <p>
                  Alternance sur 1 an , perfectionnement langages
                  <b style={{ color: "#3F63EF" }}>
                    PHP (framework : laravel), JS ( framework : Angular),
                    ProsgresSQL
                  </b>
                  <b style={{ color: "#3F63EF" }}>
                    Cahier des charges Modélisation de l'architecture
                    logicielle (UML) Développement en langage objet
                  </b>
                </p>
              </Timeline.Item>

              <Timeline.Item label="Chef de projet informatique">
                <h2>Keyce ACADEMY</h2>
                <h3>13 Janvier 2020 - 20 Mars 2020</h3>
                <p>
                  Alternance sur 2 ans Diplome obtenu Master II informatique :
                  Manager de Projet Informatique
                </p>
              </Timeline.Item>

              <Timeline.Item label="Developpeur Web Fullstack">
                <h2>CAPSULE ACADEMY</h2>
                <h3>13 Janvier 2020 - 20 Mars 2020</h3>
                <p>
                  <b style={{ color: "#3F63EF" }}>JavaScript, HTML, CSS, </b>
                  Librarie{" "}
                  <b style={{ color: "#3F63EF" }}>
                    Jquery, Node.js, MongoDB,NoSQL • React, API •
                    Bootstrap/ReactStrap/React Components
                  </b>
                </p>
              </Timeline.Item>

              <Timeline.Item label="Chef de projet digital">
                <h2>ISDD</h2>
                <h3>
                  <i>Bachelor chef de projet digital mention bien</i>
                </h3>
                <h3>Octobre 2016 - Juin 2019</h3>
                <p>
                  Réalisation de projets en tant que chef de projet,
                  <i>
                    <b> Motion design</b>
                  </i>{" "}
                  (<b style={{ color: "#3F63EF" }}>after effect</b>),{" "}
                  <i>
                    <b>Montage vidéo </b>
                  </i>
                  (<b style={{ color: "#3F63EF" }}>adobe premiere</b>)
                </p>
                <p>Communication et Publicité</p>
                <p>
                  Web design (<b style={{ color: "#3F63EF" }}>HTML,CSS</b>)
                </p>
                <p>
                  Graphisme apprentissage des logiciels{" "}
                  <i>
                    <b>PAO</b>
                  </i>{" "}
                  (
                  <b style={{ color: "#3F63EF" }}>
                    Indesign, Illustrator, Photoshop
                  </b>
                  )
                </p>
                <p>Methode agile</p>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cv;
