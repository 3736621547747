import React, { useEffect, useState } from "react";

import Competences from "./Competences";
import Footer from "./Footer";
import {
  faHome,
  faEnvelope,
  faScroll,
  faBook,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";
import { PacmanLoader } from "react-spinners";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { SunOutlined, MoonOutlined, FacebookFilled } from "@ant-design/icons";

import { Switch } from "antd";
import "../App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBarComponent from "./NavBarComponent";

library.add(fab);
function Banner({ isDarkMode, toggleMode }) {
  const [loader, setloader] = useState(true);
  const lang = useSelector((state) => state.languageReducer.language);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 1000);
  }, []);
  if (loader) {
    return (
      <div className="loader-container">
        <PacmanLoader
          type="PacmanLoader"
          color="#3f63ef"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  } else {
    return (
      <div className="body">
        <NavBarComponent />

        <div className="first-banner">
          <div className="chelou">
            <div className="donka-img"></div>
            <img
              className="margin-img"
              src="../../donka.jpg"
              alt="donka-creation"
            ></img>
          </div>

          <div className="text-banner">
            <div className="reseaux-sociaux-donka-creation">
              <a
                href="https://www.facebook.com/donkacreation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>

              <a
                href="https://www.instagram.com/donkacreation/?hl=fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-instagram" />
              </a>
              <a
                href="https://www.linkedin.com/in/donkaprat/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-linkedin" />
              </a>
            </div>

            <header>
              <div className="switch-margin">
                <Switch
                  checkedChildren={<MoonOutlined />}
                  unCheckedChildren={<SunOutlined />}
                  checked={isDarkMode} // ou checked={isDarkMode} si vous le g�rez via une variable d'�tat
                  onChange={toggleMode}
                />
              </div>

              <h1>{translation(lang, "titre")}</h1>
            </header>
            <span className="designer">Designer Graphic</span>
            <p className="text-presentation">
              {translation(lang, "presentation")}
            </p>
            <div
              class="fb-like"
              data-href="https://www.facebook.com/donkacreation"
              data-width="100"
              width="200px"
              data-layout="button_count"
              data-action="like"
              data-size="large"
              data-share="false"
            ></div>
            <Link class="btnportfolio" to="/card" aria-label="Book">
              {translation(lang, "portfolio")}
            </Link>
          </div>
        </div>
        <div className="color-services">
          <div className="mes-services">
            <h2>{translation(lang, "service")}</h2>
          </div>

          <div className="number">
            <div className="webdes">
              <span className="bold-num">01</span>
              <h3>{translation(lang, "web")}</h3>
              <p>{translation(lang, "pres-web")}</p>
            </div>

            <div className="webdes">
              <span className="bold-num">02</span>
              <h3>{translation(lang, "title-web-applis")}</h3>
              <p>{translation(lang, "web-applis")}</p>
            </div>

            <div className="webdes">
              <span className="bold-num">03</span>
              <h3>{translation(lang, "title-graphisme")}</h3>
              <p>{translation(lang, "graphisme-pres")} </p>
            </div>
          </div>
        </div>
        <Competences />
        <div className="confiance">
          <div>
            <h2>{translation(lang, "confiance")}</h2>
          </div>

          <div className="confiance-img">
            <a
              href="https://nemausus-consulting.fr/"
              target="_blank"
              rel="noopener noreferrer"
              width="25%"
            >
              <img
                src="../../logo-nemausus.svg"
                alt="logo nemausus consulting"
                width="50%"
                height="50%"
              />
            </a>
            <a
              href="https://campus-avenir.fr"
              target="_blank"
              rel="noopener noreferrer"
              width="25%"
            >
              <img
                src="../../campus-avenir.png"
                alt="logo roadcom"
                width="50%"
                height="30%"
              />
            </a>
            <a
              href="http://www.aspcn.fr/accueil.html"
              target="_blank"
              rel="noopener noreferrer"
              width="25%"
            >
              <img
                src="../../logo-aspcn.svg"
                alt="logo aspcn nimes"
                width="50%"
                height="50%"
              />
            </a>
          </div>
        </div>

        <div className="foot">
          <div className="lien-b">
            <img src="../../donka-cre.png" alt="logo donka creation"></img>
          </div>

          <div className="contact">
            <div className="carte">
              <div className="img-back-fleurs">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2878.017027882377!2d4.361007565503673!3d43.834744879115576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x12b42df2c2b48a95%3A0x34c402b7b3fc6e65!2sdonka%20prat!3m2!1d43.8357658!2d4.3616949!5e0!3m2!1sfr!2sfr!4v1586782560905!5m2!1sfr!2sfr"
                  width="600"
                  height="450"
                  frameborder="0"
                  style={{ border: 0, zIndex: 100 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="colorfooter">
          <Link
            class="mentionlegale"
            to="/mentionslegales"
            aria-label="Mentionlegales"
          >
            mentions légales / RGPD | 
          </Link>
          <a href="#">
            <span>donka creation</span>
          </a>
        </div>
      </div>
    );
  }
}

export default Banner;
