import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { translation } from "../I18n/i18n";

function Competences() {
  const lang = useSelector((state) => state.languageReducer.language);

  return (
    <div>
      <div className="competences">
        <h2>{translation(lang, "competences")}</h2>
      </div>

      <div className="gra-web-res">
        <div className="graphisme">
          <h3>{translation(lang, "title-graphisme")}</h3>
          <div className="graph">
            <img alt="photoshop" src="../../adobephotoshop.svg"></img>
            <img alt="illustrator" src="../../adobeillustrator.svg"></img>
            <img alt="indesign" src="../../adobeindesign.svg"></img>

            <img alt="lightroom" src="../../adobelightroomclassic.svg"></img>
            <img alt="premiere" src="../../adobepremiere.svg"></img>
            <img alt="xd" src="../../adobexd.svg"></img>
          </div>
        </div>
        <div className="web">
          <h3>{translation(lang, "web-design")}</h3>
          <div className="graph">
            <img alt="html5" src="../../html5.svg"></img>
            <img alt="css3" src="../../css3.svg"></img>
            <img alt="github" src="../../bootstrap.svg"></img>
            <img alt="nodejs" src="../../node-dot-js.svg"></img>
            <img alt="react" src="../../react.svg"></img>
            <img alt="redux" src="../../redux.svg"></img>
            <img alt="postman" src="../../postman.svg"></img>
            <img alt="mongodb" src="../../mongodb.svg"></img>
            <img alt="github" src="../../expressjs.png"></img>
            <img alt="github" src="../../github.svg"></img>
            <img alt="sass" src="../../sass.jpg"></img>
          </div>
        </div>
        <div className="reseaux-sociaux">
          <h3>{translation(lang, "reseaux")}</h3>
          <div className="graph">
            <img src="../../facebook-with-circle.svg" alt=""></img>
            <img src="../../instagram-with-circle.svg" alt=""></img>
            <img src="../../linkedin-with-circle.svg" alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Competences;
