import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "bootstrap";
import NavBarComponent from "./NavBarComponent";

function Grandfrais() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBarComponent />

      <div className="hallstatt">
        <div className="grandfrais-back">
          <button
            style={{
              marginTop: "10px",
              border: "none",
              outline: "none",
              marginLeft: "300px",
              borderRadius: "20px",
              backgroundColor: "#3f63ef",
              padding: "10px 20px",
            }}
          >
            <Link to="/card" style={{ color: "white" }}>
              {" "}
              Retour{" "}
            </Link>
          </button>
        </div>

        <div className="hallstatt-video">
          <img
            width="70%"
            style={{ marginLeft: -150 }}
            src="../../grandfrais.png"
            alt="flyer grand frais"
          ></img>

          <div className="title-frais">
            <h1>Flyer grand frais</h1>
          </div>
        </div>
      </div>

      <div className="client-center">
        <div className="client3">
          <div className="expertise-center">
            <span className="bold-expertise">Expertises : </span>
            <span>Print</span>
          </div>
          <div className="secteur">
            <span className="bold-expertise">Secteur : </span>
            <span> grande distribution</span>
          </div>
        </div>
      </div>
      <div className="concept-hist">
        <div className="concept">
          <h2>Projet</h2>
          <p>
            Conception et réalisation d’un flyer « Guides des salades » pour
            l’enseigne Grand Frais. Format : Pli fenêtre et portefeuille, 3
            volets. Temps de réalisation : 2 jours.
          </p>
        </div>
        <div className="text-histoire">
          <img width="100%" src="../../grandfraisint.png" alt="chateau"></img>
        </div>
      </div>

      <div className="colorfooter">
        <Link
          class="mentionlegale"
          to="/mentionslegales"
          aria-label="Mentionlegales"
        >
          mentions légales / RGPD | 
        </Link>
        <a href="#">
          <span>donka creation</span>
        </a>
      </div>
    </div>
  );
}

export default Grandfrais;
